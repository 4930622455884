import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import {RootStoreContext} from "../../../../../stores/RootStore";
import {observer} from "mobx-react-lite";

interface OwnProps {
    name: string
    onChange: (e) => void
    checked: boolean
    acknowledge: {
        name?: string,
        title?: string
    }
}

type Props = OwnProps;

const LegalAgreementApprovalInput: FunctionComponent<Props> = ({name, onChange, checked, acknowledge}) => {
    const rootStore = useContext(RootStoreContext);
    const {user, hasRole, isFCC} = rootStore.oidcStore;

    const [currentAgreementKey, setCurrentAgreementKey] = useState('');

    useEffect(() => {
        if (isFCC) setCurrentAgreementKey('fcc')
        else if (hasRole('signing')) setCurrentAgreementKey('signing')
        else setCurrentAgreementKey('')
    }, [user])

    return (<p className={'inline-flex items-center flex-wrap text-justify min-w-0 gap-x-2 mb-3'}>
        {currentAgreementKey !== '' && <input type={'checkbox'} name={name} onChange={onChange} checked={checked}
                className={'m-0 mr-1 rounded inline'}/>}
        {currentAgreementKey === 'fcc' ? <>
            You are about to acknowledge this Semi-Annual Report. Once acknowledged this can not easily be undone.
            Click save to acknowledge and please download the review if you haven't already.
        </> :
        currentAgreementKey === 'signing' ? <>
            I swear under penalty of perjury that I am,
            {<input aria-label={'full name'} className={'mx-2 border border-gray-500 max-w-fit outline-none leading-4 h-6 rounded shadow-none'} name={'name'}
                    placeholder={'Full Name'} value={acknowledge.name} onChange={onChange}/>},
            {<input aria-label={'title'} className={'mx-2 border border-gray-500 max-w-fit outline-none leading-4 h-6 rounded'} name={'title'}
                    placeholder={'title'} value={acknowledge.title} onChange={onChange}/>},
            an officer of the above-named reporting entity, and that the entity has policies and procedures in place
            to ensure that recipients satisfy the NDBEDP eligibility requirements, that the entity is in compliance
            with the Commission’s NDBEDP rules, that I have examined the foregoing reports and that all requested
            information has been provided, and all statements of fact are true and an accurate statement of the
            business activities conducted pursuant to the NDBEDP by the above-named certified program.
        </> : <>
            You are not an eligible signing officer. Upload a document with the Semi-Annual Report, the
            attestation statement, the officer’s name, title, date and signature to proceed.
        </>}
    </p>)
}
export default observer(LegalAgreementApprovalInput);