import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import SemiAnnualReportSignerForm
    from "../../../pages/reports/semiAnnualReport/components/forms/SemiAnnualReportSignerForm";
import {ISemiAnnualReport} from "../../../models/SemiAnnualReport/SemiAnnualReport";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps {
    semiAnnualReport: ISemiAnnualReport,
    action: 'review' | 'approve' | 'reject',
    agreement: string,
    onSubmit: () => void
}

type Props = OwnProps;

// @ts-ignore
const SemiAnnualReportSignerModal = NiceModal.create<Props>(({semiAnnualReport, agreement, onSubmit, action, onClose, header, snapshotId, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <SemiAnnualReportSignerForm onSubmit={onSubmit} action={action}
                                        semiAnnualReport={semiAnnualReport} {...rest}/>
        </SlideOver>
    )
})

export default SemiAnnualReportSignerModal;
